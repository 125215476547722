import React, { useEffect, useState } from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import GithubDark from '../../images/icons/github-dark.svg'

const TechSection = () => {
  let initStackData = [
    {
      image: 'images/tech-icons/react.png',
      star: 4.5,
    },
    {
      image: 'images/tech-icons/redux.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/javascript.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/next-js.svg',
      star: 3.5,
    },
    {
      image: 'images/tech-icons/sass.png',
      star: 2.5,
    },
    {
      image: 'images/tech-icons/tailwind.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/html5.png',
      star: 4.5,
    },
    {
      image: 'images/tech-icons/css3.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/bootstrap.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/git.png',
      star: 4,
    },
    {
      image: 'images/tech-icons/vscode.png',
      star: 4.5,
    },
    {
      image: 'images/tech-icons/github.png',
      star: 4.5,
    },
  ];

  const [techStackData, setTechStackData] = useState(initStackData);

  useEffect(() => {
    const handleUserKeyPress = () => {
      if (document.getRootNode().childNodes[1].className === 'dark') {
        let data = [...techStackData];
        data[11].image = GithubDark;
        setTechStackData(data);
      } else {
        let data = [...techStackData];
        data[11].image = 'images/tech-icons/github.png';
        setTechStackData(data);
      }
    };

    handleUserKeyPress()

    window.addEventListener('click', handleUserKeyPress);

    return () => {
      window.removeEventListener('click', handleUserKeyPress);
    };
  }, []);
  return (
    <div className='text-center font-poppins' id='techStack'>
      <div className='py-5'>
        <div className='text-[48px] font-bold md:leading-[26px]'>
          My Tech Stack
        </div>
        <div className='mt-7 text-2xl text-dark-content leading-[26px]'>
          Technologies I’ve been working with recently
        </div>
      </div>
      <div className='my-10 flex flex-wrap gap-y-5 md:gap-y-2 justify-around items-center mx-auto'>
        {techStackData.map(({ image, star }, i) => (
          <div
            key={i}
            className='flex justify-between items-center flex-col w-[40%] md:w-[20%] lg:w-[15%] p-4 md:p-0'
          >
            <img
              src={image}
              alt={image}
              className='w-full md:w-auto md:h-[120px]'
              width={50}
              height={50}
            />
            <div className='flex justify-center text-[#1F2626] dark:text-[#BDEBEA] text-center text-sm sm:text-xs'>
              <Rating rating={star} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Rating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating > fullStars;
  const emptyStars = 5 - Math.ceil(rating);
  return (
    <div className='flex justify-center items-center my-1'>
      {Array(fullStars).fill(<FaStar className='text-orange-500' />)}
      {halfStars && <FaStarHalfAlt className='text-orange-500' />}
      {Array(emptyStars).fill(<FaRegStar className='text-orange-500' />)}
    </div>
  );
};

export default TechSection;

import React from "react";

const HeroSection = () => {
  return (
    <div className="min-h-[90vh] sm:min-h-[75vh] flex flex-col sm:flex-row justify-center sm:justify-between items-center my-10 lg:m-10">
      <div className="text-4xl sm:text-4xl md:text-5xl md:text-[58px] md:leading-[70px] tracking-[-1px] text-solid-heading-dark dark:text-ligth font-bold">
        Hi 👋,
        <br />
        My name is <br />
        <h1 className="bg-gradiant-color text-transparent bg-clip-text inline-block">
          Husen Lokhandwala
        </h1>
        <br />I build things for web
      </div>
      {/* Added for better SEO */}
      <div className="hidden">
        <h2>Frontend Developer</h2>
        <h2>React Developer</h2>
        <h3>Husen Lokhandwala</h3>
        <h3>Hussain Lokhandwala</h3>
        <h3>Hussain Lokhandvala</h3>
        <h3>Husain Lokhandwala</h3>
        <h3>Husain Lokhandvala</h3>
      </div>
      <div className="mt-10 sm:mt-0">
        <div className="bg-gradiant-color-image dark:bg-light-border p-2 rounded-full overflow-hidden w-fit mx-auto border-gradiant-color">
          <img
            src="/images/Hero_image.png"
            className="mx-auto rounded-full"
            alt="Husen Lokhandwala"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
